/**
 *  MemberchatError - error class for Memberchar
 */
class MemberchatError extends Error {
  /**
   * constructor - creating of error instance
   *
   * @param {String} message error text
   */
  constructor(message) {
    super(message);
    this.name = 'MemberchatError';
  }
}

const script = document.querySelector('script[src*="memberchat"]');
const attributes = script.attributes;

initLinks();

window.addEventListener('load', function () {
  initMemberchat();
});

function initMemberchat() {
  if ('data-project-id' in attributes) {
    const projectId = attributes['data-project-id'].value;
    window.projectId = projectId;
    if (projectId === 'demo') {
      window.mcDemo = true;
    }
  
    if (!window.mcDemo) {
      if (!projectId || !/^.{32}$/.test(projectId)) {
        throw new MemberchatError('Memberchat id is invalid or not defined');
      }
  
      if (!('name' in attributes) || !attributes['name'].value) {
        console.warn('Attribute "name" not found or empty');
      }
  
      if (!('avatar' in attributes) || !attributes['avatar'].value) {
        console.warn('Attribute "avatar" not found or empty');
      }
    }
  
    if (window.MemberStack || window.$memberstackDom || window.Outseta || window.mcDemo) {
      if (document.querySelector('#memberchat-app')) {
        import('./js/app');
      }
  
      if (document.querySelector('#memberchat-info')) {
        import('./js/info');
      }
    } else {
      throw new MemberchatError('MemberStack and Outseta not found');
    }
  } else {
    throw new MemberchatError('Attribute "data-project-id" not found');
  }; 
}

/**
 * initLinks - find and init member links
 */
function initLinks() {
  const link = document.querySelector('[data-memberchat-member-link]');
  const memberId = document.querySelector('[data-memberchat-member-id]');

  if (link && memberId) {
    document.querySelectorAll('[data-memberchat-member-id]')
        .forEach((el) => {
          const linkEl = findLinkEl(el);

          if (linkEl) {
            linkEl.href += (/\?/.test(linkEl.href) ?
                (/&$/.test(linkEl.href) ? '' : '&') : '?') +
                'member_id=' + (el.value || el.innerText);
          }
        });
  }
}

/**
 * findLinkEl - find child link in parent elements
 *
 * @param  {HTMLElement} input input element
 * @return {HTMLElement}       link element
 */
function findLinkEl(input) {
  let currentEl = input;

  do {
    const parentEl = currentEl.parentNode;
    const link = parentEl.querySelector('[data-memberchat-member-link]');

    if (link) {
      return link;
    } else {
      currentEl = parentEl;
    }
  } while (parentEl !== document.body);

  return null;
}
